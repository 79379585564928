<template>
  <div class="page_bg error_page" style="background: #FFFFFF;">
    <van-image width="10rem" height="8rem" style="margin-top: 2.2rem;"
               src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/public/error3.png"
    />
    <div class="error_page_tip">商品已经不在啦~</div>
    <div class="error_page_msg">您可以去商城看看其他商品哦~</div>
    <div class="error_page_btn flex-r-sa-c">
      <div />
      <van-button round block type="info" native-type="submit" color="#1084D1" style="font-size: 0.4rem;width: 8.53rem;"
                  @click="$router.push('/home/index')"
      >去商城逛逛
      </van-button>
      <div />
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import kefuBtn from '@/components/kefu_btn'
export default {
  components: { kefuBtn },
  data() {
    return {

    }
  },
  methods: {
    reLogin() { this.$router.push('/user/login') },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) }
  }
}
</script>

<style lang="scss">
.error_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .van-image {
  }
  &_tip {
    font-size: 0.48rem;
    color: #333333;
    margin-top: 0.11rem;
  }
  &_msg {
    font-size: 0.37rem;
    color: #999999;
    margin-top: 0.11rem;
  }
  &_btn {
    margin-top: 1rem;
    width: 100%;
    .van-button {
      width: 3.2rem;
    }
  }
}
</style>
